import BeglauLayoutBackground from "../../../Img/BeglauLayoutBackground.jpeg";
import styled from "styled-components";
import React from "react";

const Content = styled.div`
  background-image: url(${BeglauLayoutBackground});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 1080px;
  width: 100%;
`;


const Background = () => {
	return (
		<Content/>
	)
}

export default Background
