export default () => (
	<svg id="Group_751" data-name="Group 751" xmlns="http://www.w3.org/2000/svg"
		 width="48" height="55.426" viewBox="0 0 48 55.426">
		<defs>
			<clipPath id="clip-path">
				<rect id="Rectangle_51" data-name="Rectangle 51" width="48" height="55.426" transform="translate(0 0)"
					  fill="#4b4b4b"/>
			</clipPath>
		</defs>
		<g id="Group_176" data-name="Group 176" transform="translate(0 0)" clip-path="url(#clip-path)">
			<path id="Path_84" data-name="Path 84" d="M20.356,51A14.528,14.528,0,0,0,45.528,36.484"
				  transform="translate(-8.933 -16.011)" fill="#4b4b4b"/>
			<path id="Path_85" data-name="Path 85"
				  d="M.04,13.833l9.127,5.138a17.219,17.219,0,1,1-.143,17.236L0,41.533v.036L24,55.426,48,41.569V13.857L24,0Z"
				  transform="translate(0 0)" fill="#4b4b4b"/>
		</g>
	</svg>
)
