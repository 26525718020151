import BeglauLogo from "../../../Svg/BeglauLogo.svg";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import NavigationConfig from ".././../../Config/Navigation"

const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 85px;
  height: 55px;
`;

const HeaderLeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  height: 90px;
  width: 50%;
  float: left;
`;

const HeaderRightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  height: 90px;
  width: 50%;
  float: right;
`;

const YourConfigurationButton = styled.div`
  font-size: 20px;
  color: #4B4B4B;
  margin-right: 80px;
  cursor: pointer;
`;

const ChecklistButton = styled.div`
  font-size: 20px;
  color: #4B4B4B;
  margin-right: 100px;
  cursor: pointer;
`;

const ToYourDocumentsButton = styled.button`
  background: white;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  color: #F36B23;
  border-color: #F36B23;
  border-style: solid;
  border-radius: 50px;
  border-width: 2px;
  margin-right: 85px;
  width: 330px;
  height: 60px;
  cursor: pointer;
`;

const Navigation = () => {
	const navigate = useNavigate();
	const onClick = (url: string) => {
		navigate(`/${url}`);
	}
	return (
		<div>
			<HeaderLeftPart>
				<Logo src={BeglauLogo} alt="Beglau Logo"/>
			</HeaderLeftPart>
			<HeaderRightPart>
				<YourConfigurationButton onClick={() => onClick(NavigationConfig.home)}>Ihre
					Konfiguration</YourConfigurationButton>
				<ChecklistButton onClick={() => onClick(NavigationConfig.checklist)}>Checkliste</ChecklistButton>
				<ToYourDocumentsButton onClick={() => onClick(NavigationConfig.details)}>zu ihren
					Dokumenten</ToYourDocumentsButton>
			</HeaderRightPart>
		</div>
	)
}

export default Navigation;
