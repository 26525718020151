import React from "react";
import Layout from "../../Component/Layout/Layout";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Navigation from "../../Config/Navigation";
import HeatIcon from "../../Icons/heat";
import TankIcon from "../../Icons/tank";
import Dashboard from "../../Icons/dashboard";

import HeadlineBox from "../../Component/HeadlineBox/HeadlineBox";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  min-width: 1500px;
`

const Paper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 25px;
  flex-direction: column;
  filter: drop-shadow(5px 5px 10px #00000016);
  margin-bottom: 20px;
  min-height: 350px;
`;

const PaperCenter = styled(Paper)`
  justify-content: center;
  align-items: center;
`;

const SmallPaper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 25px;
  filter: drop-shadow(5px 5px 10px #00000016);
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
`;

const PaperGroup1 = styled.div`
  display: grid;
  gap: 10px 20px;
  grid-template-rows: 350px;
  grid-template-columns: 2fr 1fr 1fr;
`;

const PaperGroup2 = styled(PaperGroup1)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
`;

const PaperButton = styled.div`
  display: flex;
  color: white;
  background-color: #4B4B4B;
  width: 50%;
  height: 275px;
  border-radius: 25px;
  filter: drop-shadow(5px 5px 10px #00000016);
  justify-content: center;
  align-items: center;
  font-size: 30px;
`;

const Row = styled.div`
  width: 100%;
`;

const TextBox = styled.div`
  color: #4B4B4B;
  font-size: 30px;
  width: 60%;
`;

const HeadlineOfBox = styled.div`
  color: #4B4B4B;
  font-size: 40px;
  font-weight: bold;
  text-align: right;
  width: 40%;
`;

const WelcomeBox = styled.div`
  display: flex;
  padding: 60px 130px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px 0;
`;

const Demand = styled.div`
  width: 40%;
`;

const DemandValue = styled.p`
  font-size: 60px;
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
`;

const DemandSubtitle = styled.p`
  font-size: 30px;
`;

const ValueContainer = styled.div`
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: self-start;
`;

const Icon = styled.div`
  width: 48px;
  height: auto;
`;

const ValueName = styled.div`
  font-size: 30px;
  padding: 0 10px;
`;

const Value = styled(ValueName)`
  font-weight: bold;
`;

const Button = styled.button`
  background: white;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  color: #F36B23;
  border-color: #F36B23;
  border-style: solid;
  border-radius: 50px;
  border-width: 2px;
  cursor: pointer;
  padding: 12px 50px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 30px;
  display: block;
  padding: 0;
  margin: 0;
  line-height: 1.5;
`;

const Bold = styled(Text)`
  font-weight: 700;
`;

const BoldOrange = styled(Bold)`
  color: #FF5100;
  padding-top: 40px;
`;


const Highlight = styled.p`
  text-align: center;
  font-size: 60px;
  display: block;
  padding: 0;
  margin: 0;
  color: #FF5100;
  line-height: 1.5;
`;

const Euro = styled.p`
  text-align: center;
  font-family: 'Rubik', sans-serif;
  color: white;
  font-size: 120px;
  font-weight: 300;
  margin: 0;
  padding: 0;
`;

const DashboardContainer = styled.p`
  width: 100%;
  text-align: center;
  padding: 40px;
`;

const Home = () => {
	const navigate = useNavigate();
	const onClick = (url: string) => {
		navigate(`/${url}`);
	}
	return (
		<Layout>
			<div>
				<HeadlineBox>
					Hallo
					<br></br>
					Herr Beglau
				</HeadlineBox>
				<Container>
					<Paper>
						<Row>
							<WelcomeBox>
								<TextBox>
									Willkommen in Ihrem persönlichen Kunden-Konto. Hier finden Sie alles rund um Ihre
									Anfrage. Sie können ihre Angaben einsehen, wichtige Dokumente für den Berater zur
									Verfügung stellen und Sie erhalten einen Überblick darüber wie es ab jetzt mit ihrer
									Anfrage weitergeht.
								</TextBox>
								<HeadlineOfBox>
									Ihre Konfiguration
									<br/><span style={{fontSize: "30px", fontWeight: "normal"}}>vom 30.03.22</span>
								</HeadlineOfBox>
								<Demand>
									<DemandValue>ca. 16800 kWh</DemandValue>
									<DemandSubtitle>Wärmebedarf pro Jahr</DemandSubtitle>
								</Demand>
								<ValueContainer>
									<Icon>
										<HeatIcon/>
									</Icon>
									<ValueName>
										Heizung
									</ValueName>
									<Value>
										8 KW
									</Value>
								</ValueContainer>
								<ValueContainer>
									<Icon>
										<TankIcon/>
									</Icon>
									<ValueName>
										Speicher
									</ValueName>
									<Value>
										600 L
									</Value>
									<div>
										<Button onClick={() => onClick(Navigation.details)}>Details ansehen</Button>
									</div>
								</ValueContainer>
							</WelcomeBox>
						</Row>
					</Paper>
					<Paper>
						<Row>
							<DashboardContainer>
								<Dashboard/>
							</DashboardContainer>
						</Row>
					</Paper>
					<PaperGroup1>
						<SmallPaper style={{backgroundColor: "#F36B23"}}>
							<Bold style={{color: "white"}}>Ersparnis pro Jahr</Bold>
							<Euro>700 €</Euro>
						</SmallPaper>
						<SmallPaper>
							<Bold>Verbrauch</Bold>
							<Text>kWh / Jahr</Text>
							<BoldOrange>17.000</BoldOrange>
							<Text>alt</Text>
						</SmallPaper>
						<SmallPaper>
							<Bold>Verbrauch</Bold>
							<Text>kWh / Jahr</Text>
							<BoldOrange>14.400</BoldOrange>
							<Text>neu</Text>
						</SmallPaper>
					</PaperGroup1>
					<PaperGroup2>
						<SmallPaper><Bold>einmalige Kosten</Bold><Text>ca. 15.000 €</Text></SmallPaper>
						<SmallPaper><Bold>laufende Kosten</Bold><Text>ca. 500 €</Text></SmallPaper>
						<SmallPaper><Bold>Förderung</Bold><Text>ca. 5.000 €</Text></SmallPaper>
					</PaperGroup2>
					<PaperCenter>
						<Bold>Gesamtkosten (Preisspanne)</Bold>
						<Highlight>10.000 - 12.300 €</Highlight>
					</PaperCenter>
					<ContainerButtons>
						<PaperButton>
							Kundenstimmen
						</PaperButton>
						<PaperButton style={{marginLeft: "16px"}}>
							FAQ
						</PaperButton>
					</ContainerButtons>
				</Container>
			</div>
		</Layout>
	);
}

export default Home;
