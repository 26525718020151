import styled from "styled-components";
import React, {ReactNode} from "react";

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 80%;
`

const Headline = styled.div`
  color: #ffffff;
  font-size: 75px;
  font-weight: 500;
  padding-left: 140px;
  padding-bottom: 45px;
`

type Props = {
	children: ReactNode;
}

const HeadlineBox = ({children}: Props) => {
	return (
		<div style={{display: "flex", justifyContent: "center"}}>
			<Box>
				<Headline>{children}</Headline>
			</Box>
		</div>
	);
}

export default HeadlineBox;
