import React from "react";
import Layout from "../../Component/Layout/Layout";
import styled from "styled-components";
import InfoEntry from "../../Component/Details/InfoEntry";
import BackButtonSymbol from "../../Svg/BackButton.svg";
import {useNavigate} from "react-router-dom";
import Navigation from "../../Config/Navigation";
import HeadlineBox from "../../Component/HeadlineBox/HeadlineBox";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
`

const Paper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 25px;
  flex-direction: column;
  filter: drop-shadow(5px 5px 10px #00000016);
  margin-bottom: 75px;
`

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
`

const PaperButton = styled.div`
  display: flex;
  color: white;
  background-color: #4B4B4B;
  width: 50%;
  height: 275px;
  border-radius: 25px;
  filter: drop-shadow(5px 5px 10px #00000016);
  justify-content: center;
  align-items: center;
  font-size: 30px;
`

const Row = styled.div`
  width: 100%;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 140px;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
`

const InfoHeadline = styled.div`
  color: #4B4B4B;
  font-size: 30px;
  font-weight: 500;
`

const Text = styled.div`
  color: #4B4B4B;
  font-size: 30px;
  width: 40%;
  padding: 60px 140px;
`

const HeadlineOfBox = styled.div`
  color: #4B4B4B;
  font-size: 40px;
  font-weight: 500;
  text-align: right;
  padding: 60px 140px;
`

const Back = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 140px;
  margin-top: 30px;
  margin-bottom: 65px;
  cursor: pointer;
`

const BackSymbol = styled.img`
  width: 28px;
  height: 28px;
`

const BackButton = styled.div`
  color: #4B4B4B;
  font-size: 23px;
  margin-left: 10px;
`

const Details = () => {
	const navigate = useNavigate();
	const onClick = (url: string) => {
		navigate(`/${url}`);
	}
	return (
		<Layout>
			<div>
				<HeadlineBox>
					Hallo
					<br></br>
					Herr Beglau
				</HeadlineBox>
				<Container>
					<Paper>
						<Row>
							<Info>
								<Text>Willkommen in Ihrem persönlichen Kunden-Konto. Hier finden Sie alles rund um Ihre
									Anfrage. Sie können ihre Angaben einsehen, wichtige Dokumente für den Berater zur
									Verfügung stellen und Sie erhalten einen Überblick darüber wie es ab jetzt mit ihrer
									Anfrage weitergeht.
								</Text>
								<HeadlineOfBox>
									Ihre Konfiguration
									<br/><span style={{fontSize: "30px", fontWeight: "normal"}}>vom 30.03.22</span>
								</HeadlineOfBox>
							</Info>
						</Row>
						<Row>
							<Column>
								<InfoDetails>
									<InfoHeadline style={{marginBottom: "50px"}}>Gebäudedaten</InfoHeadline>
									<InfoEntry entry={"Grösse"} value={"150 m²"}></InfoEntry>
									<InfoEntry entry={"Postleitzahl"} value={"19057"}></InfoEntry>
									<InfoEntry entry={"Personenzahl"} value={"4+"}></InfoEntry>
									<InfoEntry entry={"Projektbeginn"} value={"5. Oktober 2022"}></InfoEntry>
									<InfoEntry entry={"Alter Verbrauch"} value={"21.000 kWh/Jahr"}></InfoEntry>
									<InfoEntry entry={"Hüllfläche"} value={"480 m²"}></InfoEntry>
									<InfoEntry entry={"Transmissionswärmeverlust HT"} value={"0,4 W/m²K"}></InfoEntry>
									<InfoEntry entry={"Geschosse"} value={"2"}></InfoEntry>
									<InfoEntry entry={"Warmwasserbedarf"} value={"Normal"}></InfoEntry>
									<InfoEntry entry={"Dämmmaßnahmen geplant?"} value={"Ja"}></InfoEntry>
									<InfoEntry entry={"Normalaußentemperatur in °C"} value={"-12"}></InfoEntry>
									<InfoEntry entry={"Durchschn. Zieltemperatur innen in °C"} value={"20"}></InfoEntry>
									<InfoEntry entry={"Wärmeerzeuger"} value={"Gas"}></InfoEntry>
									<InfoEntry entry={"Fußbodenheizung"} value={"Ja"}></InfoEntry>
									<InfoEntry entry={"Photovoltaik"} value={"Ja"}></InfoEntry>
								</InfoDetails>
								<InfoDetails style={{marginLeft: "10%"}}>
									<InfoHeadline style={{marginBottom: "50px"}}>Wärmepumpe</InfoHeadline>
									<InfoEntry entry={"Wärmequelle"} value={"Erde"}></InfoEntry>
									<InfoEntry entry={"Kühlen"} value={"Ja"}></InfoEntry>
									<InfoEntry entry={"Einzelraumreglungen"} value={"Smart Home"}></InfoEntry>
									<InfoEntry entry={"Raumlüftung"} value={"Ja"}></InfoEntry>
									<InfoEntry entry={"Speichergröße"} value={"Premium"}></InfoEntry>
									<InfoEntry entry={"Fundamente"} value={"Nein"}></InfoEntry>
									<InfoHeadline style={{marginTop: "65px", marginBottom: "50px"}}>weitere
										Leistungen</InfoHeadline>
									<InfoEntry entry={"Fundamente"} value={"Nein"}></InfoEntry>
									<InfoEntry entry={"Kernbohrung und Tiefbau"} value={"Ja"}></InfoEntry>
								</InfoDetails>
							</Column>
							<Back onClick={() => onClick(Navigation.home)}>
								<BackSymbol src={BackButtonSymbol} alt="Back Symbol"/>
								<BackButton>zurück</BackButton>
							</Back>
						</Row>
					</Paper>
					<ContainerButtons>
						<PaperButton>
							Kundenstimmen
						</PaperButton>
						<PaperButton style={{marginLeft: "16px"}}>
							FAQ
						</PaperButton>
					</ContainerButtons>
				</Container>
			</div>
		</Layout>
	);
}

export default Details;
