import React from "react";
import BeglauLoginScreenBackground from "../../Img/BeglauLoginScreenBackground.png";
import BeglauLogo from "../../Svg/BeglauLogo.svg";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 50%;
  text-align: left;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik",serif;
  color: #4B4B4B;
  background-color: #FAF8F5;
`;

const HouseImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const Logo = styled.img`
  margin-bottom: -45px;
`;

const Content = styled.div`
  max-width: 575px;
  width: 100%;
  padding: 20px
`;

const Box = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InputField = styled.input`
  font-family: "Rubik", serif;
  background-color: #FAF8F5;
  height: 45px;
  width: 550px;
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
  border-color: #4B4B4B;
  padding: 10px;
  font-size: 20px;

  &::placeholder {
    color: #C9C9C9;
  }
`;

const LoginButton = styled.button`
  background-color: #F36B23;
  height: 65px;
  width: 575px;
  font-family: "Rubik", serif;
  font-size: 20px;
  color: white;
  border-radius: 10px;
  border-width: 0;
  cursor: pointer;
`;

const BottomTextRight = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  padding: 30px;
  font-size: 16px;
  color: #C9C9C9;
`;

const BottomTextLeft = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  padding: 30px;
  font-size: 16px;
  color: #FFFFFF;
`;

const Login = () => {
	const navigate = useNavigate();
	const onClick = () => {
		navigate("/home")
	}
	return (
		<Box>
			<Container>
				<HouseImage src={BeglauLoginScreenBackground} alt="Haus mit Wärmepumpe"/>
				<BottomTextLeft>Beglau © 2022</BottomTextLeft>
			</Container>
			<Container>
				<Content>
					<Logo src={BeglauLogo} alt="Beglau Logo"/>
					<p style={{fontSize: 75}}><strong>Willkommen</strong> <br/>bei Beglau</p>
					<p style={{marginTop: -45, marginBottom: 50, fontSize: 23}}>Legen Sie ihren
						persönlichen Kunden-Account an.</p>
					<InputField type="text" placeholder="E-Mail"></InputField><br/>
					<InputField style={{marginTop: 20, marginBottom: 50}} type="password"
								placeholder="Passwort"></InputField><br/>
					<LoginButton onClick={onClick}>Einloggen</LoginButton>
				</Content>
				<BottomTextRight>Kontakt | Impressum | Datenschutz | AGB</BottomTextRight>
			</Container>
		</Box>
	)
}

export default Login;
