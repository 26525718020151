import React, {ReactNode} from "react";
import styled from "styled-components";
import Navigation from "./Navigation/Navigation";
import Background from "./Background/Background";
import Boss from "./Boss/Boss";
import Milestones from "./Milestones/Milestones";
import Footer from "./Footer/Footer";

type Props = {
	children: ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%
`;

const Content = styled(InnerContainer)`
  margin-top: -450px;
`;

const Layout = ({children}: Props) => {
	return (
		<Container>
			<InnerContainer><Navigation/></InnerContainer>
			<InnerContainer><Background/></InnerContainer>
			<Content>{children}</Content>
			<InnerContainer><Boss/></InnerContainer>
			<InnerContainer><Milestones/></InnerContainer>
			<InnerContainer><Footer/></InnerContainer>
		</Container>);
}

export default Layout
