export default () => (<svg xmlns="http://www.w3.org/2000/svg" width="1141" height="702" viewBox="0 0 1141 702">
		<g id="Ellipse_29" data-name="Ellipse 29" transform="translate(500 76) rotate(90)" fill="none" stroke="#f36b23"
		   stroke-width="100">
			<circle cx="250" cy="250" r="250" stroke="none"/>
			<circle cx="250" cy="250" r="200" fill="none"/>
		</g>
		<g id="Ellipse_28" data-name="Ellipse 28" transform="translate(641 76)" fill="none" stroke="#f36b23"
		   stroke-width="100" stroke-dasharray="920 1600">
			<circle cx="250" cy="250" r="250" stroke="none"/>
			<circle cx="250" cy="250" r="200" fill="none"/>
		</g>
		<text id="_1200_" data-name="1200 €" transform="translate(250 336)" fill="#4b4b4b" font-size="30"
			  font-family="Rubik-Bold, Rubik" font-weight="700">
			<tspan x="-52.575" y="0">1200 €</tspan>
		</text>
		<text id="_500_" data-name="500 €" transform="translate(891 336)" fill="#4b4b4b" font-size="30"
			  font-family="Rubik-Bold, Rubik" font-weight="700">
			<tspan x="-45.06" y="0">500 €</tspan>
		</text>
		<text id="Gaspreis_pro_Jahr" data-name="Gaspreis pro Jahr" transform="translate(250 28)" fill="#4b4b4b"
			  font-size="30" font-family="Rubik-Regular, Rubik">
			<tspan x="-123.33" y="0">Gaspreis pro Jahr</tspan>
		</text>
		<text id="Wärmepumpe" transform="translate(891 28)" fill="#4b4b4b" font-size="30"
			  font-family="Rubik-Regular, Rubik">
			<tspan x="-96.045" y="0">Wärmepumpe</tspan>
		</text>
		<g id="Group_753" data-name="Group 753" transform="translate(-4741 -2419)">
			<text id="Grundlage_ist_ein_Strompreis_je_kWh_von" data-name="Grundlage ist ein Strompreis je kWh von"
				  transform="translate(5009 3115)" fill="#4b4b4b" font-size="23" font-family="Rubik-Regular, Rubik">
				<tspan x="-211.462" y="0">Grundlage ist ein Strompreis je kWh von</tspan>
			</text>
			<text id="und_ein_Gaspreis_je_kWh_von" data-name="und ein Gaspreis je kWh von"
				  transform="translate(5528 3115)" fill="#4b4b4b" font-size="23" font-family="Rubik-Regular, Rubik">
				<tspan x="-148.177" y="0">und ein Gaspreis je kWh von</tspan>
			</text>
			<text id="_0.2_" data-name="0.2 €" transform="translate(5300 3115)" fill="#ff5100" font-size="23"
				  font-family="Rubik-Regular, Rubik">
				<tspan x="-28.152" y="0">0.2 €</tspan>
			</text>
			<text id="_0.07_" data-name="0.07 €" transform="translate(5760 3115)" fill="#ff5100" font-size="23"
				  font-family="Rubik-Regular, Rubik">
				<tspan x="-34.431" y="0">0.07 €</tspan>
			</text>
			<g id="Group_747" data-name="Group 747" transform="translate(-7915 1486)">
				<circle id="Ellipse_25" data-name="Ellipse 25" cx="10" cy="10" r="10" transform="translate(13155 1612)"
						fill="#d5d6d6"/>
				<text id="_-" data-name="-" transform="translate(13165 1629)" fill="#fff" font-size="23"
					  font-family="Rubik-Regular, Rubik">
					<tspan x="-5.566" y="0">-</tspan>
				</text>
			</g>
			<g id="Group_748" data-name="Group 748" transform="translate(-7459 1486)">
				<circle id="Ellipse_25-2" data-name="Ellipse 25" cx="10" cy="10" r="10"
						transform="translate(13155 1612)" fill="#d5d6d6"/>
				<text id="_--2" data-name="-" transform="translate(13165 1629)" fill="#fff" font-size="23"
					  font-family="Rubik-Regular, Rubik">
					<tspan x="-5.566" y="0">-</tspan>
				</text>
			</g>
			<g id="Group_749" data-name="Group 749" transform="translate(-7904 1486)">
				<circle id="Ellipse_26" data-name="Ellipse 26" cx="10" cy="10" r="10" transform="translate(13244 1612)"
						fill="#d5d6d6"/>
				<text id="_" data-name="+" transform="translate(13254 1629)" fill="#fff" font-size="23"
					  font-family="Rubik-Regular, Rubik">
					<tspan x="-7.153" y="0">+</tspan>
				</text>
			</g>
			<g id="Group_750" data-name="Group 750" transform="translate(-7440 1486)">
				<circle id="Ellipse_26-2" data-name="Ellipse 26" cx="10" cy="10" r="10"
						transform="translate(13244 1612)" fill="#d5d6d6"/>
				<text id="_2" data-name="+" transform="translate(13254 1629)" fill="#fff" font-size="23"
					  font-family="Rubik-Regular, Rubik">
					<tspan x="-7.153" y="0">+</tspan>
				</text>
			</g>
		</g>
	</svg>
)
