import React from "react";
import Layout from "../../Component/Layout/Layout";
import HeadlineBox from "../../Component/HeadlineBox/HeadlineBox";
import styled from "styled-components";
import BackButtonSymbol from "../../Svg/BackButton.svg";
import EmptyChecklistEntry from "../../Component/Checklist/EmptyChecklistEntry";
import CheckedChecklistEntry from "../../Component/Checklist/CheckedChecklistEntry";
import {useNavigate} from "react-router-dom";
import Navigation from "../../Config/Navigation";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
`

const Paper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 25px;
  flex-direction: column;
  filter: drop-shadow(5px 5px 10px #00000016);
  margin-bottom: 75px;
`

const Row = styled.div`
  width: 100%;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 140px;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
`

const InfoHeadline = styled.div`
  color: #4B4B4B;
  font-size: 30px;
  font-weight: 500;
`

const Text = styled.div`
  color: #4B4B4B;
  font-size: 30px;
  width: 40%;
  padding: 60px 140px;
`

const SmallTextBox = styled.div`
  color: #4B4B4B;
  font-size: 23px;
  width: 20%;
  text-align: left;
  padding: 60px 140px;
`

const Back = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 140px;
  margin-top: 105px;
  margin-bottom: 65px;
  cursor: pointer;
`

const BackSymbol = styled.img`
  width: 28px;
  height: 28px;
`

const BackButton = styled.div`
  color: #4B4B4B;
  font-size: 23px;
  margin-left: 10px;
`

const Checklist = () => {
	const navigate = useNavigate();
	const onClick = (url: string) => {
		navigate(`/${url}`);
	}
	return (
		<Layout>
			<div>
				<HeadlineBox>
					<br></br>
					Checkliste
				</HeadlineBox>
				<Container>
					<Paper>
						<Row>
							<Info>
								<Text>Wir haben einiges vor uns. Um Ihr Projekt erfolgreich beginnen zu können, brauchen
									wir
									Ihre Unterstützung.
									<br/><br/>Wie Sie uns helfen können? Ganz einfach – um Ihre passende Wärmepumpe und
									den
									Speicher optimal auswählen und installieren zu können, brauchen wir Bilder und Werte
									von
									Ihrem Haus.
								</Text>
								<SmallTextBox>
									<span style={{fontWeight: "500"}}>Warum diese Infos zusenden?</span><br/>
									Wir benötigen diese Informationen, um Ihnen bestmöglich ein Angebot zu erstellen,
									das zu
									Ihrem Projekt passt.
									<br/><br/>
									Haben wir alle Daten erfasst, können wir mit Ihrem Projekt starten!
								</SmallTextBox>
							</Info>
						</Row>
						<Row>
							<Column>
								<InfoDetails>
									<InfoHeadline style={{marginBottom: "50px"}}>Dokumente</InfoHeadline>
									<EmptyChecklistEntry entry={"Grundrisse EG + OG"}></EmptyChecklistEntry>
									<CheckedChecklistEntry entry={"Verbrauchsabrechnungen"}></CheckedChecklistEntry>
									<EmptyChecklistEntry entry={"Grundriss Garten"}></EmptyChecklistEntry>
									<EmptyChecklistEntry entry={"Wärmeschutznachweis"}></EmptyChecklistEntry>
								</InfoDetails>
								<InfoDetails style={{marginLeft: "10%"}}>
									<InfoHeadline style={{marginBottom: "50px"}}>Fotos</InfoHeadline>
									<CheckedChecklistEntry entry={"Haus (rundum)"}></CheckedChecklistEntry>
									<CheckedChecklistEntry entry={"Heizkörper (Alle)"}></CheckedChecklistEntry>
									<CheckedChecklistEntry entry={"Aufstellort der Wärmepumpe"}></CheckedChecklistEntry>
									<EmptyChecklistEntry entry={"Hauswirtschaftsraum"}></EmptyChecklistEntry>
								</InfoDetails>
							</Column>
							<Back onClick={() => onClick(Navigation.details)}>
								<BackSymbol src={BackButtonSymbol} alt="Zurück Symbol"/>
								<BackButton>zurück</BackButton>
							</Back>
						</Row>
					</Paper>
				</Container>
			</div>
		</Layout>)
}

export default Checklist;
