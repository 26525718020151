export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="48" height="63.812"
		 viewBox="0 0 48 63.812">
		<defs>
			<clipPath id="clip-path">
				<rect id="Rectangle_52" data-name="Rectangle 52" width="48" height="63.812" fill="#4b4b4b"/>
			</clipPath>
		</defs>
		<g id="Group_752" data-name="Group 752" transform="translate(8)">
			<g id="Group_178" data-name="Group 178" transform="translate(-8 0)" clip-path="url(#clip-path)">
				<path id="Path_86" data-name="Path 86"
					  d="M14.827,42.84l6.624,12.013h14.1l-2.6-4.5H24.061S19.906,44.954,19.9,42.8c-.008-2.186,4.163-7.691,4.163-7.691h8.961L35.6,30.831l-.077-.136.054-.092H21.451Z"
					  transform="translate(-5.243 -10.822)" fill="#4b4b4b"/>
				<path id="Path_87" data-name="Path 87"
					  d="M47.779,50.348H38.888s-4.155-5.395-4.163-7.55c-.008-2.186,4.163-7.691,4.163-7.691h8.961l2.573-4.276-.077-.136-.1-.063L36.278,30.6,29.654,42.839l6.624,12.013,13.931-.029Z"
					  transform="translate(-10.486 -10.822)" fill="#4b4b4b"/>
				<path id="Path_88" data-name="Path 88"
					  d="M47.374,38.8l-3.045-1.607c1.163-2.048,2.092-4.094,2.088-5.4-.005-1.262-.906-3.224-2.04-5.2l3-1.582a1.175,1.175,0,0,0-1.1-2.078l-3.124,1.649c-1.672-2.611-3.361-4.8-3.361-4.8h-5.36L13.919,0,1.462,3.233l1.962,11,9.5,5.545h-6.3L0,32.018,6.623,44.03h6.3l-9.5,5.545-1.962,11,12.457,3.233,10.362-9.993L34.432,44.03h5.36s1.664-2.2,3.323-4.823l3.162,1.668a1.175,1.175,0,0,0,1.1-2.078M11.495,9.234l5.15-1.3,12.532,11.3Zm5.581,46.1-5.149-1.3,17.682-10ZM37.181,39.526h-8.96l-.006.009H18.129l-.005-.009H9.234s-4.154-5.395-4.163-7.55c-.008-2.186,4.163-7.691,4.163-7.691h8.961l.025-.042H28.267l.025.042h8.89s4.155,5.394,4.163,7.55c.008,2.186-4.163,7.691-4.163,7.691"
					  transform="translate(0 0)" fill="#4b4b4b"/>
			</g>
		</g>
	</svg>
)
