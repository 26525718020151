import styled from "styled-components";
import React from "react";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 25px;
`

const Entry = styled.div`
	color: #4B4B4B;
	font-size: 23px;
`

const Value = styled.div`
	color: #4B4B4B;
	font-size: 23px;
	font-weight: 500;
`

const Separator = styled.hr`
	border: 1px solid #70707050;
	width: 100%;
	margin-bottom: 35px;
`

type Props = {
    entry: string;
    value: string;
}

const InfoEntry = ({entry, value}: Props) => {
    return (
        <div>
            <Row>
                <Entry>{entry}</Entry>
                <Value>{value}</Value>
            </Row>
            <Separator></Separator>
        </div>
    );
}

export default InfoEntry;
