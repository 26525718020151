import BjoernPhoto from "../../../Img/BjoernPhoto.png";
import styled from "styled-components";
import React from "react";

const Box = styled.div`
	background-color: #FAF8F5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 580px;
	color: #4B4B4B;
`

const BjoernImage = styled.img`
	display: flex;
`

const BjoernInfo = styled.div`
	margin-left: 80px;
	flex-direction: column;
`

const BjoernName = styled.div`
	font-weight: 500;
	font-size: 40px;
`

const BjoernContact = styled.div`
	font-size: 30px;
`

const CallToActionText = styled.div`
	margin-left: 160px;
	flex-direction: column;
`

const YourPersonalContact = styled.div`
	color: #FF4E00;
	font-size: 30px;
`

const CallOrEmailUs = styled.div`
	font-size: 23px;
`

const Boss = () => {
	return (
		<Box>
			<BjoernImage src={BjoernPhoto} alt="Bild von Björn Beglau"/>
			<BjoernInfo>
				<BjoernName>Björn Beglau</BjoernName>
				<BjoernContact style={{marginTop: "60px"}}>
					<a style={{color: "#4B4B4B"}} href="tel:+49386680111">+49 3866 80111</a>
				</BjoernContact>
				<BjoernContact>
					<a style={{color: "#4B4B4B"}} href="mailto:info@beglau.de">info@beglau.de</a>
				</BjoernContact>
			</BjoernInfo>
			<CallToActionText>
				<YourPersonalContact>Ihr persönlicher <br/>Ansprechpartner</YourPersonalContact>
				<CallOrEmailUs style={{marginTop: "35px"}}>Sie haben Fragen? Rufen Sie mich <br/>gerne an oder schreiben Sie mir eine <br/>E-Mail. Wir hören uns spätestens im <br/>Beratungsgespräch.</CallOrEmailUs>
			</CallToActionText>
		</Box>
	);
}

export default Boss
