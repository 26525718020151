import BeglauWaermepumpen from "../../../Svg/BeglauWärmepumpen.svg";
import styled from "styled-components";
import React from "react";

const FooterLeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #4B4B4B;
  height: 250px;
  width: 33.3333333333%;
  float: left;
`;

const FooterMiddlePart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4B4B4B;
  height: 250px;
  width: 33.3333333333%;
  float: right;
`;

const FooterRightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #4B4B4B;
  height: 250px;
  width: 33.3333333333%;
  float: right;
`;

const BeglauDarkLogo = styled.img`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-left: 85px;
`;

const FooterTextElement = styled.div`
  color: #C9C9C9;
  font-size: 23px;
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
  padding: 0 5px;
`;

const Footer = () => {
	return (
		<div>
			<FooterLeftPart>
				<BeglauDarkLogo src={BeglauWaermepumpen} alt="Beglau Wärmepumpen Logo"/>
			</FooterLeftPart>
			<FooterRightPart>
				<FooterTextElement style={{marginRight: 85}}>Beglau © 2022</FooterTextElement>
			</FooterRightPart>
			<FooterMiddlePart>
				<FooterTextElement><Link href={"https://www.beglau.de/kontakt"} target={"_blank"}>Kontakt</Link>|<Link
					href={"https://www.beglau.de/impressum"} target={"_blank"}>Impressum</Link>|<Link
					href={"https://www.beglau.de/datenschutz"}
					target={"_blank"}>Datenschutz</Link></FooterTextElement>
			</FooterMiddlePart>
		</div>
	);
}

export default Footer
