import StepCompleted from "../../../Svg/CheckedOrangeCircle.svg";
import EmptyOrangeCircle from "../../../Svg/EmptyOrangeCircle.svg";
import EmptyGreyCircle from "../../../Svg/EmptyGreyCircle.svg";
import styled from "styled-components";
import React from "react";

const MilestonesBox = styled.div`
  	display: flex;
  	align-items: center;
	justify-content: center;
    flex-direction: column;
	background: #FFFFFF;
	height: 514px;
	width: 100%;
`;

const MilestonesText = styled.div`
    color: #4B4B4B;
    font-size: 23px;
    font-weight: 500;
    position: absolute;
    margin-right: 1175px;
    margin-bottom: 275px;
`

const Container = styled.div`
  	display: flex;
  	align-items: center;
	justify-content: center;
    margin-top: 85px;
`;

const OrangeLine = styled.hr`
    width: 260px;
    border-width: 1.5px;
    background-color: #F97F21;  
    border-style: solid;
    border-color: #F97F21;
`

const GreyLine = styled.hr`
    width: 260px;
    border-width: 1.5px;
    background-color: #C9C9C9;
    border-style: solid;
    border-color: #C9C9C9;
`

const CircleSVG = styled.img`
    height: 65px;
    width: 65px;
`

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const TextInCircle = styled.div`
    position: absolute;
    color: #F97F21;
    font-size: 20px;
    font-weight: 500;
`

const TextBelowCircle = styled.div`
    position: absolute;
    color: #F97F21;
    font-size: 20px;
    font-weight: 500;
    margin-top: 145px;
    text-align: center;
`

const Milestones = () => {
    return (
        <MilestonesBox>
            <Container>
                <MilestonesText>Wie geht es weiter?</MilestonesText>
                <Circle>
                    <CircleSVG src={StepCompleted} alt="Abgeschlossener Schritt"/>
                    <TextBelowCircle>Anfrage</TextBelowCircle>
                </Circle>
                <OrangeLine></OrangeLine>
                <Circle>
                    <CircleSVG src={EmptyOrangeCircle} alt="Schritt 2"/>
                    <TextInCircle>2</TextInCircle>
                    <TextBelowCircle>Daten <br/>vervollständigen</TextBelowCircle>
                </Circle>
                <GreyLine></GreyLine>
                <Circle>
                    <CircleSVG src={EmptyGreyCircle} alt="Schritt 3"/>
                    <TextInCircle style={{color: "#C9C9C9"}}>3</TextInCircle>
                    <TextBelowCircle style={{color: "#C9C9C9", fontWeight: "normal"}}>Beratungs-<br/>gespräch</TextBelowCircle>
                </Circle>
                <GreyLine></GreyLine>
                <Circle>
                    <CircleSVG src={EmptyGreyCircle} alt="Schritt 4"/>
                    <TextInCircle style={{color: "#C9C9C9"}}>4</TextInCircle>
                    <TextBelowCircle style={{color: "#C9C9C9", fontWeight: "normal"}}>Angebot und <br/>Terminfindung</TextBelowCircle>
                </Circle>
                <GreyLine></GreyLine>
                <Circle>
                    <CircleSVG src={EmptyGreyCircle} alt="Schritt 5"/>
                    <TextInCircle style={{color: "#C9C9C9"}}>5</TextInCircle>
                    <TextBelowCircle style={{color: "#C9C9C9", fontWeight: "normal"}}>Montage</TextBelowCircle>
                </Circle>
            </Container>
        </MilestonesBox>
    );
}

export default Milestones
