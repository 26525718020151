import React from 'react';
import {
	BrowserRouter,
	Route,
	Routes,
} from "react-router-dom";

import Login from "./Page/Login/Login";
import Home from "./Page/Home/Home";
import Details from "./Page/Details/Details";
import Checklist from "./Page/Checklist/Checklist"
import Navigation from "./Config/Navigation";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login/>}>
					</Route>
					<Route path={`/${Navigation.home}`} element={<Home/>}>
					</Route>
					<Route path={`/${Navigation.details}`} element={<Details/>}>
					</Route>
					<Route path={`/${Navigation.checklist}`} element={<Checklist/>}>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
