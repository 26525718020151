import styled from "styled-components";
import React from "react";
import CheckedOrangeCircle from "../../Svg/CheckedOrangeCircle.svg";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 25px;
`

const Entry = styled.div`
	color: #4B4B4B;
	font-size: 23px;
`

const OrangeCircle = styled.img`
	width: 32px;
    height: 32px;
    margin-right: 50px;
`

const Separator = styled.hr`
	border: 1px solid #70707050;
	width: 100%;
	margin-bottom: 35px;
`

type Props = {
    entry: string;
}

const CheckedChecklistEntry = ({entry}: Props) => {
    return (
        <div>
            <Row>
                <OrangeCircle src={CheckedOrangeCircle} alt="Kreuzchen in orangen Kreis"></OrangeCircle>
                <Entry>{entry}</Entry>
            </Row>
            <Separator></Separator>
        </div>
    );
}

export default CheckedChecklistEntry;